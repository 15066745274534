 #mapid {
	height: 600px;
	margin-bottom: 3rem;
	margin-top: -3rem;
}
.view {
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: default;
    margin-bottom: 2rem;
}
.view .mask, .view .content {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2rem 1rem 1rem 1rem;
}
.view img {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
}
.view h2 {
    text-transform: uppercase;
    color: $white;
    text-align: center;
    position: relative;
    background: rgba(0, 0, 0, 0.8);
}
.view p {
    position: relative;
    color: $white;
    text-align: center
}
.view a.info {
    display: inline-block;
    text-decoration: none;
    padding: 7px 14px;
    background: $black;
    color: $white;
}
.view-second img { 	
	transition: all 0.2s ease-in;
}
.view-second .mask { 
	@include background-opacity($primary-color, 0.8);
	opacity: 0;
	transform: translate(250px, 145px) rotate(45deg);
	transition: all 0.2s ease-in-out;
	width: 250%;
	height: 250%;
}
.view-second h2 {
    background: transparent;
    transform: translate(400px, -400px);
	transition: all 0.2s ease-in-out;
}
.view-second p { 
	transform: translate(-400px, 400px);
	transition: all 0.2s ease-in-out;
}
.view-second a.info { 
    transform: translate(0px, 100px);
	transition: all 0.2s 0.1s ease-in-out;
} 
.view-second .button {
	background-color: transparent;
	color: $white;
	border: 2px solid white;
	&:hover {
		background-color: $white;
		color: $primary-color;
	}
}
.view-second:hover .mask { 
	opacity:1; 
	transform: translate(-80px, -125px) rotate(45deg);
}							  
.view-second:hover h2 { 
	transform: translate(0px,0px);
	transition-delay: 0.3s; 
}
.view-second:hover p { 
    transform: translate(0px,0px); 
	transition-delay: 0.4s;
}
.view-second:hover a.info { 
	transform: translate(0px,0px); 
	transition-delay: 0.5s;
}
.view-second .button {
	@extend .secondary;
}
.longform_wide {
	h2 {
		text-align: center;

	}
	h3 {
		text-align: center;
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
	.card .card-section h3 {
		margin-top: 0;
	}
}
.big_number {
	color: $white;
    font-size: 4rem;
    margin-bottom: 0;
    line-height: 1.1em;
    text-shadow: 2px 2px $black;
}
.fearless-skew img {
  webkit-clip-path: polygon(0 0, 100% 0%, 100% 92%, 0% 100%);
  clip-path: polygon(0 0, 100% 0%, 100% 92%, 0% 100%);
}
#fearless_discovery  {
	.column {
		margin-bottom: 3rem;
		text-align: center;
		p, h3 {
			text-align: left;
			margin-left: .5rem;
			margin-right: .5rem;
		}
	}
	img {
		height: 250px;
	}
}
.page-template-page-fearless-profile {
	#page-full-width {
		margin-top: 1rem;
	}
	header {
		h3 {
			margin-bottom: 0;
			color: $medium-gray;
		}
		h1 {
			margin-top: 0;
		}
	}
	.fearless-skew img {
		margin-bottom: 2rem;
	}
}