.page-template-page-society #front-hero {
	min-height: 250px;
}

.give-container {
	.give-form {
		.tabs {
			border: none;
			background: none;
		}
		.tabs-content {
			border: none;
		}
		.tabs-panel {
			padding: 0;
		}
	}
}

$circle-size: 240px;
$circle-background: #e1dddd;
$circle-color: $primary-color;
$inset-size: 185px;
$inset-color: $white;
$transition-length: 1.5s;

.ko-progress-circle {
	margin: 20px auto;
	width:  $circle-size;
	height: $circle-size;
	font-size: 3rem;

	background-color: $circle-background;
	border-radius: 50%;
		.ko-progress-circle__slice, .ko-progress-circle__fill {
			width:    $circle-size;
			height:   $circle-size;
			position: absolute;
			-webkit-backface-visibility: hidden;
			transition: transform $transition-length;
			border-radius: 50%;
		}
		.ko-progress-circle__slice {
			clip: rect(0px, $circle-size, $circle-size, $circle-size/2);
			.ko-progress-circle__fill {
				clip: rect(0px, $circle-size/2, $circle-size, 0px);
				background-color: $circle-color;
			}

	}
	.ko-progress-circle__overlay {
		width:       $inset-size;
		height:      $inset-size;
		position:    absolute;
		margin-left: ($circle-size - $inset-size)/2;
		margin-top:  ($circle-size - $inset-size)/2;
    	line-height: $inset-size;
    	text-align: center;
		background-color: $inset-color;
		border-radius: 50%;
		span {
			font-size: 1.85rem;
		}
	}

	$i: 1;
	$increment: 180deg / 100;
	@while $i <= 100 {
		&[data-progress='#{$i}'] {
				.ko-progress-circle__slice.full, .ko-progress-circle__fill {
          transform: rotate($increment * $i);
				}	
				.ko-progress-circle__fill.ko-progress-circle__bar {
					transform: rotate($increment * $i * 2);
				}
      $i: $i + 1;
		}
	}
}
#give-sidebar {
	padding-top: 2rem;
	.accordion {
		background: $white;
		.accordion-title {
			color: $black;
			border: none;
			font-size: 1rem;
			padding-left: 10px;
			&:hover, &:focus {
				background-color: $secondary-color;
			}
		}
	}
}