#profile-meta {
	margin-bottom: 1rem;
	#directoryArea {
		list-style-type: none;
		margin-left: 0;
	}
}
#profile {
	.related-profile {
		border-top: 1px solid $medium-gray;
		padding-top: 1rem;
		margin-top: 2rem;
	}
}