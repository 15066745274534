.tabs-title {
	a {
		border: 1px solid $primary-color;
		background-color: $white;
		color: $primary-color;
		&:hover {
			color: $primary-color;
		}
	}
}
.tabs-title.is-active {
	a:hover {
		color: $white;
	}
}