h1, h2, h3, h4 {
	font-family: $header-font-family;
}
h1 {
	line-height:1.1em;
	margin-bottom: 1rem;
}
h2 {
  line-height: 1.1em;
}
h3 {
	font-size: 1.5rem;
  font-weight: bold;
}
h4 {
	font-size: 1.125rem;
	line-height: 1.1em;	
  font-weight: bold;
}
blockquote{
  font-size: 1.4em;
  width:90%;
  margin:50px auto;
  font-style:italic;
  color: $medium-gray;
  padding:1.2em 30px 1.2em 75px;
  border-left:8px solid $primary-color ;
  line-height:1.6;
  position: relative;
  background:$secondary-color;
}

blockquote::before{
  font-family:Arial;
  content: "\201C";
  color: $primary-color;
  font-size:4em;
  position: absolute;
  left: 10px;
  top:-10px;
}

blockquote::after{
  content: '';
}
blockquote p {
  display: inline;
}
blockquote cite {
  margin-top: 1rem;
  color: $black;
  font-weight: bold;
  font-style: normal;
  font-size: 1rem;
}
.subhead {
  @include breakpoint(medium) {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1rem;
  }
}
dd {
  margin-bottom: 2rem;
  padding-left: 1rem;
}
pre {
    white-space: pre-wrap;
    background-color: $secondary-color;
    border: 1px solid $medium-gray;
    padding: 5px;
    margin-bottom: 1rem;
  code {
    border: none;
  }
}
.clear {
  clear: both;
}
.hidden {
  display: none;
}
abbr[title] {
  text-decoration: none;
}
.alignright .wp-caption-text {
  padding-left: 1rem;
}
.alignleft .wp-caption-text {
  padding-right: 1rem;
}
span.credit {
  text-transform: uppercase;
  color: $medium-gray;
  font-style: normal;
}
.feature-quote {
    margin: 2rem 0;
  }

  .feature-quote-quote {
    background: $medium-gray;
    padding: $global-padding;
    border-radius: $global-radius;
    margin-bottom: 1.875rem;
    position: relative;
    color: $white;

    p {
      margin-bottom: 0;
      font-size: 1.25rem;
    }

    &::after {
      content: '';
      bottom: -1.25rem;
      left: 3.5rem;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 1.25rem 1.125rem 0 1.125rem;
      border-color: $medium-gray transparent transparent transparent;
      position: absolute;
    }
  }

  .feature-quote-person {
    display: flex;
    margin-left: 1.65rem;
    align-items: center;
  }

  .feature-quote-avatar, .feature-quote-avatar img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }

  .feature-quote-name {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0;
    margin-left: 1rem;
  }

  .feature-quote-info {
    text-transform: uppercase;
    font-size: 14px;
    color: $light-gray;
    margin-bottom: 0;
    margin-left: 1rem;
  }
.fearless-icon {
  max-width: 150px;
  @include breakpoint(small only) {
      float: right;
      margin-bottom: 2rem;
  }
}