#page {
	padding-top: 1rem;
	.main {
		@include breakpoint(medium) {
			order: 2;
		}
	}
}
.hero, .hero img {
	width: 100%;
	@supports (object-fit: cover) {
		object-fit: cover;
	}
}
.page, .single-job-posting {
	.hero, .hero img {
		@supports (object-fit: cover) {
			max-height: 300px;
		}
	}
}