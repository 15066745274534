.header, .top-bar, .top-bar ul {
	background-color: $primary-color;
}
.header {
	position: relative;
	#headerbg {
		background-color: $primary-color;
		position: absolute;
		width: 100%;
		height: 100%;
		display: block;
		z-index: 2;
	}
}
#top-bar-menu {
	padding: 0;
	position: relative;
	z-index: 5;
	#logo-svg {
		height: 41.5px;
	}
	#logo {
		padding: 0;
		a {
			padding: 5px 16px;
			@include breakpoint(medium only) {
				padding: 10px 0;
			}
		}
		width: 280px;
		@include breakpoint(medium) {
			width: 400px;
		}
	}
	.top-bar .top-bar-left, .top-bar .top-bar-right {
		flex: 0 0 auto;
	}
	#small-menu {
		flex: 0 0 10%;
	}
	.top-bar-left {
		@include breakpoint(small) {
			flex: 0 0 auto;
		}
	}
}
#main-nav {
	li {
		font-family: "Open Sans", sans-serif;
		a, button {
			padding: .5rem 1rem;
			height: 45px;
			line-height: 25px;
			color: $white;
			font-weight: bold;
			text-transform: uppercase;
			display: block;
			@include breakpoint(large) {
				height: 70px;
				line-height: 45px;
				padding: .5rem .85rem;
			}
		}
		button {
			padding: .5rem .5rem;
		}
		:hover {
			background-color: $white;
			color: $primary-color;
			text-decoration: none;
		}
		button {
			cursor: pointer;
			margin-top: -5px;
			height: 50px;
			@include breakpoint(large) {
				height: 75px;
			}
		}
	}
}
#search-container {
	width: 100%;
	position: absolute;
	z-index: 1;
	transform: translateY(-300%);
	transition: transform 0.5s;
	label {
		width: 100%;
	}
}
#search-container.open-active {
		transform: translateY(0);
	}
.screen-reader-text {
	display: none;
}
#offcanvas-nav {
	padding: 1rem;
	li {
		border-bottom: 1px solid #222;
		font-family: "Open Sans", sans-serif;
		a {
			color: #222;
			text-transform: uppercase;
			padding: .7rem 1rem;
			line-height: 1;
			&:hover {
				background-color: #222;
				color: $white;
			}
		}
	}
	.menu-item-object-custom {
		display: none;
	}
}