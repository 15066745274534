.landing-page {
  .page-title {
    display: none;
  }
  .main {
    padding-top: 3rem;
  }
}
#front-hero {
  position: relative;
  .hero, .hero img {
    max-height: 42rem;
  }
  .marketing {
    @include breakpoint(medium) {
      max-width: 35rem;
      position: absolute;
      top: 10px;
      left: 0;
      left: 15%;
      top: 25%;
    }
    background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
    h1 {
      color: $white;
    }
    p {
      color: $white;
    }
  }
}
.link_resources {
	padding-top: 3rem;
	padding-bottom: 3rem;
	ul {
		list-style-type: none;
		margin-top: 2rem;
		margin-left: 0;
	}
	li a {
			display: block;
	    background-color: $secondary-color;
	    color: $black;
	    padding: 1rem;
	    margin-bottom: 1rem;
	    font-weight: 700;
	    width: 100%;
	    position: relative;
	    &:hover {
	    	background-color: $medium-gray;
	    	color: $white;
	    }
	}
}
.longform-small {
	padding-top: 2rem;
	padding-bottom: 2rem;
}
#landing-content {
	padding-top: 2rem;
	h1 {
		display: none;
	}
}