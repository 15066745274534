#feature {
	#featured-hero {
	  height: rem-calc(200);

	  @include breakpoint(medium) {
	    height: rem-calc(300);
	  }
	  @include breakpoint(large) {
	    height: rem-calc(450);
	  }
	  @include breakpoint(xlarge) {
	    height: rem-calc(600);
	  }
	}
	@include breakpoint(small only) {
		#featured_banner img {
		    max-height: 250px;
		    object-fit: cover;
		    width: 100%;
		}
	}
	@include breakpoint(large) {
		#featured_banner {
			padding-left: 0;
			height: auto;
			min-height: 1100px;
			clear: both;
			img {
				float: left;
				height: 100vh;
				object-fit: cover;
				transform: rotate(0);
			}
		}
		#longformintro {
			padding-top: 1rem;
			height: 100vh;
			> div {
				margin-top: auto;
				margin-bottom: auto;
			}
			p:nth-of-type(1):first-letter {
			  color: $primary-color;
			  float: left;
			  font-family: $header-font-family;
			  font-size: 5em;
			  line-height: 70px;
			  padding-top: 4px;
			  padding-right: 8px;
			}
		}
	}
	@include breakpoint(medium) {
		#longformintro p:nth-of-type(1):first-letter {
				  color: $primary-color;
				  float: left;
				  font-family: $header-font-family;
				  font-size: 5em;
				  line-height: 70px;
				  padding-right: 8px;

		}
	}
	.headline {
		margin-bottom: 2rem;
	}
	#feature-post {
		font-size: 1.125rem;
		header {
			position: relative;
			margin-bottom: 2rem;
		}
	}
	.subhead-container {
		position: relative;
		width: 100%;
		max-height: 300px;
		overflow: hidden;
		margin-top: 2rem;
		img {
			width: 100%;
			margin-bottom: 2rem;
			-webkit-transition: all 1s ease;
    		transition: all 1s ease;
    		filter: brightness(100%);
		}
		.subhead {
			margin-top:10px;
			width: 100%;
			text-align: center;
			opacity: 0;
			-webkit-transition: all 1s ease-in-out;
    		transition: all 1s ease-in-out;
    		position: absolute;
    		top: 5%;
			h2 {
				color: $white;
    			text-shadow: 1px 1px 2px $black;
    			font-size: 2rem;
    			border-bottom: none;
    			@include breakpoint(medium) {
    				font-size: 2.5rem;
    			}
			}
			@include breakpoint(medium) {
				top: 40%;
			}
		}
		@include breakpoint(medium) {
			margin-bottom: 2rem;
		}
	}
	.simple-sub {
		color: $primary-color;
		padding-top: 3rem;
		font-size: 2rem;
		margin-bottom: 1rem;
		@include breakpoint(medium) {
			font-size: 3rem;
		}
	}
	.interlude {
		padding-top: 2rem;
		padding-bottom: 2rem;
		margin-top: 4rem;
		margin-bottom: 4rem;
		border-top: 1px solid $light-gray;
		border-bottom: 1px solid $light-gray;
		padding-left: 2rem;
		padding-right: 2rem;
		font-size: 1.2222em;
	}
	#fearless_footnotes li {
		font-size: 1rem;
	}
	.cryo-special {
		font-style: normal;
		font-size: 1rem;
		box-shadow: 0 0 6px rgba(0,0,0,0.5);
		border-right: 1px solid $light-gray;
		border-left: .5em solid $primary-color;
	}
	.on {
		.subhead {
			opacity: 1;
		}
		img {
			filter: brightness(40%);
		}
	}
	.parallax-window {
	    min-height: 600px;
	    background: transparent;
	    margin-bottom: 2rem;
	    margin-top: 2rem;
	}
	.photo-gallery {
		.item {
			position: relative;
			.caption {
				bottom: 0;
				text-align: center;
				width: 100%;
				color: $white;
				background-color: $black;
				padding: .5rem 0;
				@include breakpoint(medium) {
					position: absolute;
				}
			}
		}
	}
	.callout {
		margin-top: 1rem;
		margin-bottom: 2rem;
	}
	#longform-about {
		text-align: center;
		img {
			margin-bottom: 2rem;
		}
	}
	.embed-container {
		margin-top: 1rem;
		margin-bottom: 2rem;
	}
	.oral-history {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
	footer {
		.primary-callout {
			margin-bottom: 0;
		}
		.dark-callout {
			margin-top: 0;
			margin-bottom: 0;
			.gfield_label {
				color: $white;
			}
		}
	}
	#subnav {
		li {
			font-size: 0.875em;
		}
	}
	.feature-quote-person {
		margin-left: 0;
		@include breakpoint(medium) {
			margin-left: 1.65rem;
		}
	}
	.feature-copy {
		.card {
			background-color: $medium-gray;
			color: $white;
			h3 {
				color: $white;
				text-shadow: 0px 2px 3px black;
			}
		}
	}
	.feature-title {
		margin-top: .5rem;
	}
	.oral-history-person {
		margin-bottom: .5rem;
	}
	.timeline {
		h2 {
			text-align: center;
			margin-bottom: 2rem;
		}
		.timeline-container {
			background-color: $medium-gray;
			padding: 1rem;
			img {
				margin-bottom: 1rem;
			}
		}
	}
	.image-caption {
		font-weight: bold;
	    font-style: italic;
	    padding-left: 1rem;
	    padding-top: 1rem;
	    display: block;
	    font-size: 1.5rem;
	}
	.feature-quote {
		text-align: center;
	}
	img {
		width: auto;
		display: inline-block;
		margin-bottom: .5rem;
	}
	.secondary-quote {
		font-style: italic;
	}
	.secondary-quote-person {
		margin-top: 2rem;
		font-weight: bold;
	}
	.secondary-quote-info {
		text-transform: uppercase;
		font-size: 12px;
		color: #777;
	}
	.article-footer {
		margin-top: 2rem;
		border-top: 8px solid $primary-color;
		padding-top: 2rem;
	}
	.parallax {
		padding: 5em 0;
		background: 50% 50% / cover no-repeat;
		background-attachment: fixed;
		height: 600px;
		margin-bottom: 2rem;
		margin-top: 2rem;
	}
	.wp-video, .mejs-container, .mejs-container video {
		width: auto !important;
	}
	.aligncenter .wp-caption, .wp-caption-text {
		width: auto !important;
		text-align: left;
	}
	.mejs-poster-img {
		height: 100%;
	}
	.fearless_img-comp img {
		width: 100%;
		height: 100%;
	}
	.wp-caption, .wp-caption p.wp-caption-text {
		font-size: .85rem;
		color: #757575;
		font-style: italic;
	}

}