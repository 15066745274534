.activity-content {
	margin:2rem;
	h2 {
		margin-bottom: 2rem;
	}
	h3 {
		margin-top: 2rem;
		margin-bottom: 1rem;
	}
	h4 {
		margin-top: 2rem;
		margin-bottom: 1rem;
	}
	li {
		margin-bottom: .85rem;
		ul {
			margin-top: .85rem;
		}
	}
}