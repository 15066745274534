.dark-callout.filter-bar {
  padding: 0;
}
#isotope-filters {
  min-height: 2rem;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  li {
    list-style-type: none;
    margin-left: 0;
    float: left;
    margin-right: 1rem;
    font-size: .85em;
    margin-top: .4rem;
    margin-bottom: 1rem;
  a {
    color: $white;
    text-transform: uppercase;
    border: 1px solid $secondary-color;
    padding: .5rem;
    font-weight: bold;
  }
  a.selected {
      border: 1px solid $white;
      background-color: $white;
      color: $medium-gray;

  }
  }
}
#staff-directory {
  .directory {
    margin-top: 2rem;
    clear: both;
  }
  #search-directory {
    margin-bottom: 2rem;
  }
  .item {
    list-style-type: none;
    margin-bottom: 2rem;
    div {
      border: 1px solid $secondary-color;
      h3, p, h4 {
        padding-left: 1rem;
        padding-right: 1em;
      }
      h3 {
        padding-top: .5rem;
        font-size: 1.35rem;
      }
      h4 {
        margin-bottom: 1rem;
        font-size: .9rem;
      }
      p {
        padding-bottom: 1rem;
      }
    }
  }
}