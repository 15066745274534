.news {
	margin-top: 2rem;
	clear: both;
	.item {
	    list-style-type: none;
	    margin-bottom: 2rem;
	}
}
#featured-story {
	margin-bottom: 2rem;
	h2 {
		margin-top: 1rem;
	}
}