.lazy-background {
	background-image: url("/wp-content/uploads/smush-placeholder.png");
}
#home-feature {
	position: relative;
	height: 400px;
	@include breakpoint(medium) {
		height: 750px;
	}
	#home-fearless-img {
		height: 400px;
		@include breakpoint(medium) {
			height: 750px;
		}
  		img {
  			width: 100%;
  			height: 100%;
  			object-fit: cover;
  		}
  	}
	#home-fearless {
		position: absolute;
		text-align: center;
	  	top: 85%;
	  	left: 50%;
	  	transform: translate(-50%, -50%);
	  	@include breakpoint(medium) {
	  		top: 83%;
	  	}
	}
	h2 {
		text-transform: uppercase;
		font-weight: bold;
		margin-bottom: 3rem;
		font-size: 4rem;
		line-height: 4.5rem;
		color: $white;
		text-shadow: 2px 2px 4px #0a0a0a;
		@include breakpoint(medium) {
			font-size: 8rem;
			line-height: 7rem;
		}
		a {
			color: $white;
		}
		a:hover {
			color: $primary-color;
			text-decoration: none;
		}
	}
	.button {
		text-transform: uppercase;
		font-weight: bold;
		font-family: $header-font-family;
		font-size: 1.25rem;
		@include breakpoint(medium) {
			font-size: 1.5rem;
		}
	}
}
#home-news {
	background-color: #f1f1f1;
	padding-top: 1rem;
	padding-bottom: 2rem;
	@include breakpoint(medium) {
		padding-bottom: 6rem;
	}
	#home-news-heading {
		margin-top: 8rem;
		margin-bottom: 4rem;
		position: relative;
		.center-title {
			text-transform: uppercase;
			color: #6B6B6B;
			font-size: 1.5rem;
			letter-spacing: 0.2rem;
			margin: 0 auto;
			width: 60%;
			z-index: 2;
			position: relative;
			background-color: #f1f1f1;
			@include breakpoint(medium) {
				width: 30%;
			}
		}
		span {
			display: block;
			height: 1px;
			background-color: #88a4af;
			position:absolute;
			left: 5%;
			width: 90%;
			z-index: 1;
			top: 50%;
			@include breakpoint(medium) {
				width: 70%;
				left: 15%;
			}
		}
	}
	#home-news-story1 {
		@include breakpoint(medium) {
			margin-bottom: 2rem;
		}
	}
	.home-news-story {
		h3 {
			font-weight: 700;
			margin-top: 1rem;
		}
		p {
			color: $medium-gray;
			font-size: 1rem;
		}
		.home-more {
			text-transform: uppercase;
			font-family: $header-font-family;
			font-weight: 700;
		}
		@include breakpoint(small only) {
			margin-bottom: 2rem;
		}
	}
}
#home-event {
	padding-top: 6rem;
	padding-bottom: 6rem;
	img {
		width: 100%;
	}
	h2, span {
		color: $primary-color;
		font-size: 1rem;
		text-transform: uppercase;
		margin-bottom: 1rem;
		font-family: $header-font-family;
		letter-spacing: 0.2rem;
		font-weight: bold;
		line-height: 1.1em;
		margin-bottom: 1rem;
		display: block;
	}
	h2 {
		color: #6b6b6b;
		font-weight: bold;
		margin-top: 1rem;
	}
	h3 {
		font-size: 2rem;
		margin-bottom: 1rem;
	}
	p {
		font-family: $header-font-family;
		font-weight: bold;
		line-height: 2rem;
	}
	p a {
		font-family: $header-font-family;
		text-transform: uppercase;
	}
	.event-details {
		@include breakpoint(medium) {
			padding-left: 2rem;
		}
	}

}
#home-mission {
	padding-top: 8rem;
	padding-bottom: 8rem;
	margin-bottom: 6rem;
	color: $primary-color;
	text-align: center;
	background-image: url('/wp-content/uploads/helix-repeat.png');
	background-repeat: repeat;
	p {
		font-size: 1.25rem;
		line-height: 2rem;
		@include breakpoint(medium) {
			font-size: 2rem;
			line-height: 3rem;
		}
		font-weight: bold;
	}
}
#home-initiatives {
	.large-initiative.visible {
		background-image: url("/wp-content/uploads/initiative-crest.jpg");
	}
	.small-initiative-top.visible {
		background-image: url("/wp-content/uploads/initiative-blue.jpg");
	}
	.small-initiative-bottom.visible {
		background-image: url("/wp-content/uploads/initiative-diversity.jpg");
	}
	.banner-initiative.visible {
		background-image: url("/wp-content/uploads/initiative-spark.jpg");
	}
	margin-bottom: 6rem;
	.initiative {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		color: white;
		text-align: center;
		@include breakpoint(small only) {
			margin-bottom: 1rem;
		}
		div {
			padding-left: 1rem;
			padding-right: 1rem;
			@include breakpoint(large) {
				padding-left: 2rem;
				padding-right: 2rem;
			}
			h2 {
				text-transform: uppercase;
				font-weight: bold;
				font-size: 3rem;
				text-shadow: 2px 2px 4px #0a0a0a;
			}
		}
		a {
			color: $white;
		}
	}
	.large-initiative {
		height: 400px;
		@include breakpoint(medium) {
			margin-right: .5rem;
			height: 508px;
		}
		div {
			padding-top: 9rem;
			@include breakpoint(medium) {
				padding-top: 12rem;
			}
			p {
				font-size: 1.5rem;
				text-shadow: 2px 2px 4px #0a0a0a;
			}
		}
	}
	.small-initiative {
		height: 200px;
		@include breakpoint(medium) {
			margin-bottom: .5rem;
			height: 250px;
		}
		div {
			padding-top: 4rem;
			@include breakpoint(medium) {
				padding-top: 6rem;
			}
			h2 {
				font-size: 2rem;
			}
		}
	}
	.banner-initiative {
		div {
			padding-left: 0;
			padding-right: 0;
		}
		height: 400px;
		@include breakpoint(medium) {
			height: 200px;
		}
		.grid-x {
			margin: 0 auto;
			padding-top: 4rem;
			padding-left: 1rem;
			padding-right: 1rem;
			@include breakpoint(large) {
				padding-left: 4rem;
				padding-right: 4rem;
				padding-top: 5rem;
			}
		}
		.button {
			text-transform: uppercase;
			font-size: 1.25rem;
			padding-left: 2rem;
			padding-right: 2rem;
		}
		p {
			text-shadow: 2px 2px 4px #0a0a0a;
			@include breakpoint(medium) {
				margin-top: -0.5rem;
			}
		}
	}

}