.for-print {
	display: none !important; 
}
@media print { 
	.for-print {
		display: block !important;
	}
	.header .for-print {
		content: url("https://morgridge.org/wp-content/uploads/logo.png");
		display: block;
		width: 400px;
		height: 47px;
		position: relative;
	}
	.for-print::after { /* will not show if element replacement is supported */
	  content: " (" attr(id) ")"; 
	}
	#main-nav, .sidebar, #aside, .footer-info, .footer #social, #search-container, #featured_banner, #longformintro #subnav {
		display: none;
	}
	.entry-content, .main, #longformintro {
		width: 100% !important;
	}
	.accordion-content {
		display: block;
	}
}