.footer {
  background-color: $secondary-color;
  color: $black;
  padding: 4rem 3rem 2rem;
  margin-top: 3rem;
  img {
    margin-bottom: 2rem;
  }
  li a {
    font-size: 1.2em;
  }
  a {
    color: $black;
    &:hover {
      text-decoration: underline;
    }
  }
  #footer-nav {
    list-style: none;
    margin-left: 0;
    li {
      margin-bottom: .5rem;
      display: block;
    }
    @media screen and (min-width: 40em) {
      li {
        float: left;
        margin-bottom: 0;
        display: inline-block;
        padding-right: 5%;
        &:last-child {
          margin-bottom: 1rem;
        }
      }
    }
  }
  .button {
    border: 1px solid $black;
      &:hover {
        text-decoration: none;
        color: $black;
    }
  }
  #copyright {
    text-align: left;
    font-size: .85em;
    margin-top: 1rem;
    border-top: 1px solid $medium-gray;
    padding-top: 1rem;
    color: $dark-gray;
    a {
      text-decoration: underline;
    }
  }
  #social {
    text-align: right;
    .fa:hover {
      color: $primary-color;
    }
    ul {
      li {
        float: none;
        display: inline-block;
        padding-right: 5%;
      }
    }
  }
  .fa {
    color: $black;
  }
}
