#search-page {
	h2 {
		margin-bottom: 2rem;
	}
	label {
		width: 100%;
	}
	article:not(.content-not-found) {
		margin-bottom: 2rem;
		border-top: 1px solid $secondary-color;
		padding-top: 1rem;
	}
	.pagination {
		li {
			font-size: 1rem;
		}
	}
}