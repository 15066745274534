.dark-callout {
	background-color: $dark-gray;
	color: $white;
	padding-top: 3rem;
	padding-bottom: 3rem;
	a {
		color: $white;
		&:hover {
			text-decoration: underline;
		}
	}
	.dark-container {
		background-color: $medium-gray;
		padding: 1rem;
	}
	.outline:hover {
		color: $black;
	}
}
.center-title {
	text-align: center;
	font-weight: bold;
	margin-bottom: 1rem;
	font-family: $header-font-family;
	span {
		display: block;
		font-size: .65em;
		font-weight: 400;
		text-transform: uppercase;
	}
}
.primary-callout {
	background-color: $primary-color;
	color: $white;
	padding-top: 3rem;
	padding-bottom: 3rem;
	a {
		color: $white;
		text-decoration: underline;
	}
	.button {
		text-decoration: none;
		&:hover {
			color: $primary-color;
		}
	}
}
.secondary-callout {
	background-color: $tertiary;
	padding: 2rem 0;
	margin: 3rem 0;
	h2 {
		text-align: center;
		padding: 2rem 0;
	}
}
.longform_wide {
	padding-top: 2rem;
}
#home_intro {
	background-color: $secondary-color;
	color: $black;
	padding: 3rem 0;
	@include breakpoint(medium) {
		font-size: 120%;
	}
	.button {
		border: 1px solid $medium-gray;
		margin-bottom: 0;
		color: black;
	}
}
#dna-ribbon {
	padding-top: 4rem;
	padding-bottom: 4rem;
	margin-bottom: 3rem;
	color: $primary-color;
	text-align: center;
	background-image: url('/wp-content/uploads/helix-repeat.png');
	background-repeat: repeat;
	.hero {
		position: absolute;
		z-index: -1;
		top: 200px;
		left: 20%;
		max-height: 400px;
		@include breakpoint(large) {
			top: 150px;
		}
		img {
			object-fit: scale-down;
		}
	}
}
.box-gradient {
	background: rgb(64,13,55);
	background: linear-gradient(103deg, rgba(64,13,55,1) 0%, rgba(185,0,20,1) 40%, rgba(186,32,37,1) 100%);
	padding: 4rem 0;
}
.ribbon-holder {
	position: relative;
	.ribbon-text {
		font-weight: bold;
		padding: 1rem;
		font-size: 1.5em;
		text-transform: uppercase;
		--f: .5em; /* control the folded part*/
		  --r: .8em; /* control the ribbon shape */
		  position: absolute;
		  bottom: 40%;
		  right: calc(-1*var(--f));
		  line-height: 1.8;
		  background: #b90014;
		  border-top: var(--f) solid #999a99;
		  border-left: var(--r) solid #0000;
		  clip-path: 
		    polygon(var(--r) 100%,100% 100%,100% var(--f),calc(100% - var(--f)) 0,
		      calc(100% - var(--f)) var(--f),var(--r) var(--f));
		    a {
		    	color: $white;
		    }
	}
}
.box-center {
	padding: 2rem;
	@include breakpoint(medium) {
		padding-top: 4rem;
	}
	h2 {
		color: $primary-color;
		text-transform: uppercase;
	}
	.button {
		background-color: $white;
		border: 1px solid $primary-color;
		color: $primary-color;
	}
	.button:hover {
		background-color: $primary-color;
		color: $white;
	}
}
.box-secondary {
	background-color: $secondary-color;
}
.box-photo {
	img {
		width: 100%;
	}
}
.box-career {
	.cell {
		height: 350px;
		text-align: center;
		background-repeat: no-repeat;
		background-size: cover;
		margin-bottom: 1.5rem;
		h2 {
			padding-top: 150px;
			text-transform: uppercase;
			font-size: 2.5rem;
		}
		a {
			color: $white;
		}
	}
}
.box-quote {
	margin-top: 1rem;
	p {
		color: $white;
	}
	.box-quote-text {
		padding: 1rem;
		background: rgb(185,0,20);
		background: linear-gradient(180deg, rgba(185,0,20,1) 0%, rgba(81,23,47,1) 100%);
		h3 {
			color: $white;
			span {
				margin-top: .5rem;
				display: block;
				color: $tertiary;
				font-size: 1rem;
			}
		}
	}
	.box-quote-image {
		position: relative;
		span {
			position: absolute;
			width: 32px;
			height: 32px;
			display: block;
			bottom: 1rem;
			right: 1rem;
			color: $white;
			background-color: $primary-color;
			font-size: 80px;
			line-height: 75px;
			font-family: Arial, Helvetica, sans-serif;
			padding-left: 3px;
		}
	}
}
.box-research {
	padding: 1rem;
	background-position: right;
	background-repeat: no-repeat;
	@include breakpoint(medium) {
		margin-bottom: 2rem;
	}
	div {
		@include breakpoint(medium) {
			width: 70%;
		}
	}
}
.box-frontiers {
	@include breakpoint(medium) {
		background-image: url('/wp-content/uploads/ResearchPage-2_combo_biology.svg');
	}
}
.box-tools {
	@include breakpoint(medium) {
		background-image: url('/wp-content/uploads/ResearchPage-2_combo_tools.svg');
	}
}
.box-society {
	@include breakpoint(medium) {
		background-image: url('/wp-content/uploads/ResearchPage-2_combo_society.svg');
	}
}