.card {
	background-color: transparent;
	border: 1px solid $secondary-color;
	.card-section {
		h3 {
			margin-bottom: 1rem;
		}
	}
}
.vertical-card {
	display: block;
	padding-right: 1em;
	margin-bottom: 2rem;
	img {
		float: left;
		margin-right: 1em;
		max-width: 150px;
	}
}