#career-listings {
	padding: 1rem;
	h2 {
		color: $medium-gray;
		font-size: 2em;
	}
	ul {
		margin: 0;
		li {
			list-style-type: none;
			border-bottom: 1px dotted $medium-gray;
			margin-bottom: 2rem;
			span {
				text-transform: uppercase;
				font-family: $header-font-family;
				color: $medium-gray;
				font-weight: bold;
			}
		}
	}
}