#lab_news {
	span {
		font-size: .85em;
		color: $secondary-color;
	}
}
.lab-photos {
	margin: 2em 0;
	color: $light-gray;
	font-size: 80%;
}

.page-template-page-lab {
	.tagline  {
		h5 {
			font-size: 85%;
			float: right;
			a {
				color: $secondary-color;
			}
		}
		h1 {
			clear: both;
		}
	}
	#front-hero {
		@include breakpoint(small) {
			min-height: 250px;
		}
	}
	#sidebar {
		padding-top: 0;
	}
}
.circle {
	border-radius: 50%;
	margin-bottom: 1rem;
}
#lab-bar {
	background-color: $medium-gray;
	.small-10 {
		color: $white;
		font-family: "Open Sans", sans-serif;
		a {
			color: $white;
		}
	}
	.small-2 {
		text-align: right;
		a {
			color: $secondary-color;
		}
	}

}
#lab-extra {
	padding-top: 2rem;
}