.path-video.dark-callout {
	padding-top: 1rem;
	padding-bottom: 2rem;
	margin-bottom: 2rem;
}
.path {
 	background-color: $dark-gray;
 	padding: 1rem;
 	margin-bottom: 3rem;
 	h3 {
 		color: $white;
 		text-align: center;
 		margin: 0 0 1rem 0;
 		a {
 			color: $white;
 		}
 		a:hover {
 			text-decoration: underline;
 		}
 		span {
 			display: block;
 			font-size: .6em;
 			font-weight: normal;
 			text-transform: uppercase;
 		}
 	}
 	p {
 		margin-bottom: 0;
 	}
 	figure {
 		overflow: hidden;
 		position: relative;
 	}
 }
 .inactive {
 	figure img {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
 	}
 }
.active .path figure img {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.active .path figure:hover img {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
}
.corner-ribbon {
	 width: 200px;
	  background: $medium-gray;
	  position: absolute;
	  top: 25px;
	  left: -50px;
	  text-align: center;
	  line-height: 50px;
	  letter-spacing: 1px;
	  color: $white;
	  transform: rotate(-45deg);
	  -webkit-transform: rotate(-45deg);
	top: auto;
	right: -50px;
	bottom: 25px;
	left: auto;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
}