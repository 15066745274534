a:hover {
	text-decoration: underline;
}
html {
	scroll-behavior: smooth;
}
.align-center {
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.more {
	float: right;
}
.callout, .card {
	background-color: $secondary-color;
	border: none;
}
.callout.right {
	margin-left: 1rem;
	width: 300px;
	float: right;
}
.embed-container {
    height: 0;
    overflow: hidden;
    padding-bottom: 56%;
    position: relative;
    margin-bottom: 15px;
    iframe {
    	height: 100%;
	    left: 0;
	    position: absolute;
	    top: 0;
	    width: 100%;
    }
}
.hideContent {
    overflow: hidden;
    height: 3rem;
}
.show-more {
	display: inline;
}
.alignright {
	float: right;
	padding-left: 1rem;
}
.wp-caption {
	background: transparent;
	.wp-caption-text {
		color: $light-gray;
	}
}
.wp-block-embed-vimeo>figcaption, .wp-block-image .aligncenter>figcaption, .wp-block-image .alignleft>figcaption, .wp-block-image .alignright>figcaption, .wp-block-image.is-resized>figcaption {
	color: $light-gray;
	display: table-cell;
}
.wp-block-image .aligncenter, .wp-block-image .alignleft, .wp-block-image .alignright, .wp-block-image.is-resized {
	display: block;
	margin-bottom: 2rem;
}
.wp-block-image .aligncenter {
	text-align: center;
}
.wp-block-image .alignleft, .wp-block-image .alignright {
	width: 50%;
	@include breakpoint(medium){
		width: 25%;
	}
}
.scrollable {
	overflow: scroll;
}
img.wow {
  transform: translateX(30%) scale(0.95); // They are hidden by default
  opacity: 0;
  transition: all .5s;
}

img.wow.active {
  opacity: 1;
  transform: translateX(0%) scale(1); // This brings them back in frame
}
.blocks-gallery-grid {
	list-style-type: none;
	.blocks-gallery-item__caption {
		color: $light-gray;
		display: table-cell;
	}
}

.carousel-wrapper {
  overflow: hidden;
  width: 100%;
}
.carousel-wrapper * {
  box-sizing: border-box;
}
.carousel {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.carousel__photo {
  opacity: 0;
  position: absolute;
  top:0;
  width: 100%;
  margin: auto;
  padding: 1rem 4rem;
  z-index: 100;
  transition: transform .5s, opacity .5s, z-index .5s;
  img {
  	width: 100%;
  }
}
.carousel__photo.initial,
.carousel__photo.active {
  opacity: 1;
  position: relative;
  z-index: 900;
}
.carousel__photo.prev,
.carousel__photo.next {
  z-index: 800;
}
.carousel__photo.prev {
  transform: translateX(-100%);
}
.carousel__photo.next {
  transform: translateX(100%);
}
.carousel__button--prev,
.carousel__button--next {
  position: absolute;
  top:50%;
  width: 3rem;
  height: 3rem;
  background-color: transparent;
  transform: translateY(-50%);
  border-radius: 50%;
  cursor: pointer; 
  z-index: 1001; /* Sit on top of everything */
  border:1px solid black;
/*  opacity: 0;  Hide buttons until carousel is initialised 
  transition:opacity 1s;*/
}
.carousel__button--prev {
  left:0;
}

.carousel__button--next {
  right:0;
}
.carousel__button--prev::after,
.carousel__button--next::after {
  content: " ";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  left: 54%;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  transform: translate(-50%, -50%) rotate(135deg);
}

.carousel__button--next::after {
  left: 47%;
  transform: translate(-50%, -50%) rotate(-45deg);
}