#metabolism-directory {
	input:focus {
	  outline:none;
	  border-color: $medium-gray;
	}
	.sort:hover {
	  text-decoration: none;
	  background-color:$primary-color;
	}
	.sort:focus {
	  outline:none;
	}
	.sort:after {
	  display:inline-block;
	  width: 0;
	  height: 0;
	  border-left: 5px solid transparent;
	  border-right: 5px solid transparent;
	  border-bottom: 5px solid transparent;
	  content:"";
	  position: relative;
	  top:-10px;
	  right:-5px;
	}
	.sort.asc:after {
	  width: 0;
	  height: 0;
	  border-left: 5px solid transparent;
	  border-right: 5px solid transparent;
	  border-top: 5px solid $white;
	  content:"";
	  position: relative;
	  top:4px;
	  right:-5px;
	}
	.sort.desc:after {
	  width: 0;
	  height: 0;
	  border-left: 5px solid transparent;
	  border-right: 5px solid transparent;
	  border-bottom: 5px solid $white;
	  content:"";
	  position: relative;
	  top:-4px;
	  right:-5px;
	}
}
.gform_footer input[type="submit"] {
    font-size: 1rem;
}
.gform_wrapper.gravity-theme .gform_footer {
    padding: 0;
}