#tribe-events-pg-template, .tribe-events-pg-template {
	padding: 0;
}
.tribe-events-ajax-loading, .tribe-events-visuallyhidden {
	display: none;
}
#tribe-events-content {
	max-width: 1100px;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 1rem;
}
.single-tribe_events {
	#page-full-width {
		margin-top: 0;
		#featured-hero {
			margin-bottom: 1rem;
		}
	}
	.tribe-events-sub-nav {
		display: none;
	}
}
.tribe-events-back {
	display: none;
}
.tribe_events {
	@extend .grid-x, .grid-margin-x;
	.tribe-events-event-image {
		display: none;
	}
}
.single-tribe_events .tribe-events-single-event-description {
	@extend .cell, .medium-8;
}
.single-tribe_events .tribe-events-event-meta {
	@extend .cell, .medium-4;
	margin: 0;
	.tribe-venue {
		margin-bottom: 1.5rem;
		margin-left: 1rem;
	}
	.tribe-events-meta-group {
		h3 {
			padding-top: 1rem;
			margin-bottom: 1rem;
		}
		float: none;
		width: 100%;
		dt {
			@extend h4;
		}
		dd {
			font-size: 1rem;
			margin-left: 1rem;
			margin-bottom: 1rem;
		}
	}

}
h2.tribe-events-page-title {
	text-align: left;
}
.tribe-events-l-container {
	padding: 0 !important;
}
.tribe-events-c-view-selector {
	display: none;
}
.tribe-events-calendar-list__month-separator-text {
	@extend h2;
}