#researchAffiliate .card {
	text-align: center;
	padding-bottom: 1rem;
}
#research_news {
	background: $secondary-color; /* Old browsers */
	background: -moz-linear-gradient(top,  $secondary-color 0%, $secondary-color 75%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 75%, $secondary-color 75%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 70%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  $secondary-color 0%,$secondary-color 75%,rgba(255,255,255,1) 75%,rgba(255,255,255,1) 75%,$secondary-color 75%,rgba(255,255,255,1) 75%,rgba(255,255,255,1) 70%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  $secondary-color 0%,$secondary-color 75%,rgba(255,255,255,1) 75%,rgba(255,255,255,1) 75%,$secondary-color 75%,rgba(255,255,255,1) 75%,rgba(255,255,255,1) 70%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	padding: 2rem 0 0;
	min-height: 370px;
	@breakpoint(medium) {
		min-height: 400px;
	}
	h2 {
		color: $medium-gray;
		text-align: center;
		margin-bottom: 2em;
		font-weight: bold;
	}
	span {
		display: inline-block;
		line-height: 200px;
		letter-spacing: .05em;
		font-size: 180px;
		height: 210px;
		overflow-y: hidden;
		color: lighten($secondary-color, 2%);
		position: absolute;
		z-index: 1;
	}

	.medium-4 > div {
		height: 220px;
		z-index: 2;
		position: relative;
		background-repeat: no-repeat;
		background-size: cover;
		a {
			color: white;
			text-align: center;
			width: 100%;
			display: block;
			padding-top: 15%;
			padding-bottom: 15%;
			padding-left: 10px;
			padding-right: 10px;
			font-size: 1.5em;
			font-family: $header-font-family;
			font-weight: bold;
			text-shadow: 0 0 0.2em black, 0 0 0.2em black, 0 0 0.1em black, 0 0 0.1em black ;
		}
		&:hover {
			transform: translateY(-10px);
			box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
		}
		@include breakpoint(small only) {
			margin-bottom: 3em;
		}
		&:before {
			content: '';
			width: 4px;
			background: #fff;
			height: 20px;
			position: absolute;
			bottom: 100%;
			left: 50%;
			margin-left: -2px;
			-webkit-transition: height .3s ease;
			-moz-transition: height .3s ease;
			-ms-transition: height .3s ease;
			-o-transition: height .3s ease;
			transition: height .3s ease;
		}
		&:after {
			content: '';
			width: 16px;
			background: none;
			border: 4px solid #fff;
			height: 16px;
			border-radius: 50%;
			position: absolute;
			bottom: 100%;
			left: 50%;
			margin-left: -8px;
			margin-bottom: 18px;
			-webkit-transition: all .3s ease;
			-moz-transition: all .3s ease;
			-ms-transition: all .3s ease;
			-o-transition: all .3s ease;
			transition: all .3s ease;
		}
	}
	@include breakpoint(small only) {
		background: $secondary-color;
	}
}
.page-template-page-outreach #research_news {
	margin-top: 2rem;
}
.page-template-page-research .more {
	margin-bottom: 2rem;
}
#research_intro {
	.hero, .hero img {
		@supports (object-fit: cover) {
			max-height: 500px;
		}
	}
	.intro {
		padding: 1rem;
	}
	h1 {
		margin-top: 4rem;
		text-align: center;
		font-weight: bold;
	}
	p {
		text-align: center;
		@extend .subhead;
		padding-left: 1rem;
		padding-right: 1rem;
		margin-bottom: 3rem;
	}
	.button {
		text-align: center;
		font-size: 1.125em;
		.fa {
			font-size: .85em;
			padding-left: 5px;
			padding-right: 5px;
		}
	}
}