#single {
	padding-top: 1rem;
	.entry-title {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
	.byline {
		color: $black;
	}
}
.bluesky {
	margin-top: 2rem;
}
.bluesky-person {
	text-align: center;
	margin-bottom: 2rem;
	img {
		border: 5px solid $secondary-color;
	}
	h3 {
		font-size: 1rem;
	}
}
.bluesky-content {
	border: 5px solid $secondary-color;
	padding: 2rem;
	position:relative;
	@include breakpoint(small only) {
		margin-bottom: 2rem;
	}
	p:last-child {
		margin-bottom: 0;
	}
	@include breakpoint(small only) {
		&:before {
			border: solid transparent;
			bottom: 100%;
			left: 50%;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: transparent;
			border-bottom-color: $secondary-color;
			border-width: 36px;
			margin-left: -36px;
		}
		&:after {
			border: solid transparent;
			bottom: 100%;
			left: 50%;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: transparent;
			border-bottom-color: $secondary-color;
			border-width: 30px;
			margin-left: -30px;
		}

	}
	@include breakpoint(medium) {
		&:before {
			border: solid transparent;
			right: 100%;
			top: 50px;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: transparent;
			border-right-color: $secondary-color;
			border-width: 36px;
			margin-top: -36px;
		}
		&:after {
			border: solid transparent;
			right: 100%;
			top: 50px;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: transparent;
			border-right-color: $secondary-color;
			border-width: 30px;
			margin-top: -30px;
		}
	}
}
.blue-sky-footer {
	margin-top: 2rem;
}