 
 [class^="fa-"]:before, [class*=" fa-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.fa-search:before { content: '\e800'; } /* '' */
.fa-download:before { content: '\e801'; } /* '' */
.fa-arrows-cw:before { content: '\e802'; } /* '' */
.fa-clock:before { content: '\e803'; } /* '' */
.fa-right-dir:before { content: '\e804'; } /* '' */
.fa-twitter:before { content: '\f099'; } /* '' */
.fa-facebook:before { content: '\f09a'; } /* '' */
.fa-beaker:before { content: '\f0c3'; } /* '' */
.fa-linkedin:before { content: '\f0e1'; } /* '' */
.fa-angle-down:before { content: '\f107'; } /* '' */
.fa-shield:before { content: '\f132'; } /* '' */
.fa-youtube:before { content: '\f167'; } /* '' */
.fa-instagram:before { content: '\f16d'; } /* '' */
.fa-bank:before { content: '\f19c'; } /* '' */
.fa-tree:before { content: '\f1bb'; } /* '' */
.fa-newspaper:before { content: '\f1ea'; } /* '' */
.fa-commenting-o:before { content: '\f27b'; } /* '' */

.fa-2x {
  font-size: 24px;
}
.fa-3x {
  font-size: 36px;
}
.fa-4x {
  font-size: 48px;
}