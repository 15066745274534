// Page Sidebar
.sidebar {
	@include breakpoint(medium) {
		order: 1;
	}
  .page_item .children, .page-item-7831 {
  	display: none;
  }
  .current_page_item .children, .current_page_parent .children {
	 display: block;
	 margin-left: 1em;
  }
  h3 a, h3 a:visited {
    color: $black;
    &:hover {
      color: $primary-color;
    }
  }
  ul {
  	margin-left: 0;
    li {
      display: inline-block;
      width: 100%;
      a {
      display: inline-block;
      padding: .5em;
      width: 100%;
      &:hover {
        color: $primary-color;
        text-decoration: underline;
      }
    }
    }
  .page_item  {
  	background-color: $secondary-color;
  	margin-bottom: 1em;
  }
  }
  .children {
    li {
      margin-bottom: 0;
    }
  }
}
// Post Sidebar
#story-sidebar {
  margin-top: 2rem;
  padding: 0;
  width: 100%;
  .subscribe {
    .gform_required_legend {
      display: none;
    }
    .gform_footer {
      padding-top: 0 !important;
    }
    @include breakpoint(small only) {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    p {
      font-size: .875em;
      padding: .5em .5em .5em 0;
      margin-bottom: 0;
    }
    .ginput_container input {
      margin-bottom: 1rem;
    }
  }
  .crp_related {
    padding: 0.5rem;
    padding-top: 1rem;
    border-top: 8px solid $primary-color;
    h3 {
      margin-left: .5rem;
      padding-top: 1rem;
    }
    img {
      float: left;
      margin-right: 1rem;
      @include breakpoint(small only) {
        width: 100%;
        margin-right: 0;
        float: none;
        margin-bottom: .5rem;
      }
    }
    li {
      margin-bottom: 2rem;
    }
    .crp_title {
      display: block;
      padding-top: 1rem;
      @extend h4;
      font-weight: normal;
      @include breakpoint(small only) {
        clear: both;
        padding-top: 0;
      }
    }
  }
  #gform_fields_1 {
    margin-bottom: 0;
  }
}
// Careers Sidebar
#job-sidebar {
  font-size: .85rem;
  .diversity {
    text-align: center;
    font-style: italic;
  }
}
// Path to Better Health Sidebar
#path-sidebar {
  border-top: 8px solid $primary-color;
  padding-top: 2rem;
  margin-top: 2rem;
}