.button {
	border-radius: 5px;
}
.outline {
	background-color: transparent;
	border: 2px solid $white;
	&:hover {
		background-color: $white;
		color: transparent;
		text-decoration: none;
	}
}
.button-group {
	.button {
		border-radius: 0;
	}
	.button:first-child {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}
	.button:last-child {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}
}